@font-face {
  font-family: "Futura";
  src: url("./Fonts/Futura-Medium.eot");
  src: local("Futura Medium"), local("Futura-Medium"),
    url("./Fonts/Futura-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./Fonts/Futura-Medium.woff2") format("woff2"),
    url("./Fonts/Futura-Medium.woff") format("woff"),
    url("./Fonts/Futura-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
/* @font-face {font-family: "Book Antiqua"; src: url("//db.onlinewebfonts.com/t/127984ac535ca158ad9724f752ade6a6.eot"); src: url("//db.onlinewebfonts.com/t/127984ac535ca158ad9724f752ade6a6.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/127984ac535ca158ad9724f752ade6a6.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/127984ac535ca158ad9724f752ade6a6.woff") format("woff"), url("//db.onlinewebfonts.com/t/127984ac535ca158ad9724f752ade6a6.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/127984ac535ca158ad9724f752ade6a6.svg#Book Antiqua") format("svg"); }
*/

body,
html {
  height: 100%;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: "Futura";

}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Book Antiqua";
  background-size: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg {
  background-color: #004084;
  background-image: url("https://ctt.asymp.org/images/background/ASPattern_2024.png");
  min-height: 100vh;
}
.futuraFont, .futuraFuture {
  font-family: "futura-pt" !important;
  text-align: left;
}
svg path {
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
svg {
  height: 100%;
}

.responsive-image {
  display: block;
  max-width: 100%; /* Make the image scale to fit its container */
  height: auto; /* Maintain the aspect ratio */
  margin: 0 auto; /* Center the image horizontally */
  box-shadow: 0.5rem 0.5rem 0 rgba(220, 102, 30, 1.0); border-radius: 0.25rem; border: 0.25rem solid rgba(238, 127, 0, 1.0);

}

.custom-control-label{
  cursor: pointer;
}